<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="transparent"
    height="70"
    flat
  >
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-toolbar-title class="font-weight-light text-h5" v-text="pageName" />

    <v-spacer />

    <help-button class="mr-5" />

    <user-profile-button />
  </v-app-bar>
</template>

<script>
// Utilities
import { sync } from "vuex-pathify";
import { getPageTitleFromRoutePath } from "../../utils/RoutesUtils";
import HelpButton from "./widgets/HelpButton.vue";
import UserProfileButton from "./widgets/UserProfileButton.vue";

export default {
  name: "DefaultBar",

  components: {
    HelpButton,
    UserProfileButton,

    DefaultDrawerToggle: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        "./widgets/DrawerToggle"
      ),
    DefaultSearch: () =>
      import(
        /* webpackChunkName: "default-search" */
        "./widgets/Search"
      ),
  },

  computed: {
    ...sync(["drawer", "mini"]),
    pageName() {
      return getPageTitleFromRoutePath(this.$route.path);
    },
  },
};
</script>
