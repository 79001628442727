<template>
  <div>
    <v-menu left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mt-n2 ml-n2"
          fab
          small
          elevation="1"
          height="44"
          width="44"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-card class="pt-5 px-5 pb-5">
        <p class="text-h4">
          {{ null == user || null == user.username ? "" : " " + user.username }}
        </p>

        <base-button href="/logout" :is_accent="true" text="Log out" />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

